




















import { Component, Prop, Vue } from 'vue-property-decorator';
import CoreStoreMutations from '@/constants/store/modules/core/mutations';
import { namespace } from 'vuex-class';
import FilterRendererSelect from '@/typings/filters/types/FilterRendererSelect';
import FilterRenderers from '@/constants/filters/FilterRenderers';

const coreStoreModule = namespace('core');

@Component({})
export default class FilterSelect extends Vue {
  @coreStoreModule.Mutation[CoreStoreMutations.SET_FILTERS_RENDERER_DATA]: (
    // eslint-disable-next-line max-len
    data: { rowIndex: number, columnIndex: number, filterIndex: number, data: any, filterRenderer: FilterRenderers }
  ) => void;

  @Prop({ required: true, type: Number })
  rowIndex!: number;

  @Prop({ required: true, type: Number })
  columnIndex!: number;

  @Prop({ required: true, type: Number })
  filterIndex!: number;

  @Prop({ required: true, type: Object as () => FilterRendererSelect })
  filter!: FilterRendererSelect;

  @Prop({ type: Object as () => FilterRenderers })
  filtersRendererType!: FilterRenderers;

  get value() {
    return this.filter.data as (any[] | undefined);
  }

  set value(value: any[] | undefined) {
    this[CoreStoreMutations.SET_FILTERS_RENDERER_DATA]({
      rowIndex: this.rowIndex,
      columnIndex: this.columnIndex,
      filterIndex: this.filterIndex,
      data: value || null,
      filterRenderer: this.filtersRendererType,
    });
  }
}
